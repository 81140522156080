import React, {useEffect, useState} from "react";
import {Card, Title, Text, Button, Checkbox} from '@mantine/core';


function getArticleWidth(): number{
    const {innerWidth: width} = window;
    const defaultImageWidth = 540
    if(width <= (defaultImageWidth + 30)){
        return (width - 50)
    }
    return defaultImageWidth
}

const SHADOW = '0px 0px 20px 5px rgba(240,0,0,0.2)'

interface Props{
    article: Article,
    onSelectionChanged: (id: number, isSelected: boolean) => void
}

function Article({article, onSelectionChanged}: Props){

    const [articleWidth, setArticleWidth] = useState(getArticleWidth())
    const [selected, setSelected] = useState<boolean>(false)

    useEffect(() => {

        function handleResize(){
            setArticleWidth(getArticleWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)

    }, [])

    function openInNewTab(url: string){
        window.open(url, '_blank', 'noopener, noreferrer')
    }

    function selectArticle(isSelected: boolean){
        setSelected(isSelected)
        onSelectionChanged(article.id, isSelected)
    }


    return (
        <div>

            <Card shadow="sm" p="lg" radius="md" withBorder style={{margin: 10, padding: 10, width: articleWidth, boxShadow: selected? SHADOW : ""}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%', marginBottom: -55}}>
                    <Checkbox
                        color='red'
                        radius='xl'
                        size='xl'
                        checked={selected}
                        onChange={(event) => selectArticle(event.currentTarget.checked)}
                        style={{marginRight: 10}}
                    />
                </div>

                <Card.Section>
                    <img
                        onClick={() => selectArticle(!selected)}
                        src={`data:image/jpeg;base64,${article.picture}`}
                        width={articleWidth}
                        alt={"Illustration of the Article"}
                        style={{margin: 10, cursor: 'pointer'}}
                    />
                </Card.Section>
                    <Title>{article.title}</Title>
                    <Text weight={500}>{article.detail}</Text>
                <Button  className="Button" onClick={() => openInNewTab(article.link)}>Read more</Button>
            </Card>
        </div>
    )
}

export default Article