import {doc, getDoc} from 'firebase/firestore'
import {db} from '../firebase'


export async function getCompetition(id: string): Promise<Competition>{
    const docRef = doc(db, "competitions", id)
    const docSnap = await getDoc(docRef)

    if(!docSnap.exists()){
        return {disciplines:  {}, name: "not_found", id: id, location: '', date: 0}
    }
    // @ts-ignore
    return docSnap.data()
}

export async function getAthletesBestResults(): Promise<AthletesBestResults>{
    const docRef = doc(db, "athleteInformation/athleteResults")
    const docSnap = await getDoc(docRef)

    if(!docSnap.exists()){
        return {
            athleteInformation: {}
        }
    }
    // @ts-ignore
    return docSnap.data()
}

export async function getCompetitionOverviewArchive(): Promise<Competition[]>{
    const docRef = doc(db, "overview/competitions_archive")
    const docSnap = await getDoc(docRef)

    if(!docSnap.exists()){
        return []
    }
    //@ts-ignore
    return docSnap.data().competitions
}
