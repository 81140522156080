import React from "react";
import {colors} from "../../colors";
import {compareCompetitionDate, timestampToHumanReadableString} from "../../services/utils";

interface Props {
    archiveOverviewData: Competition[],
    onSelected: (id: string) => void
}

function CompetitionOverviewArchive({archiveOverviewData, onSelected}: Props) {

    return (
        <div style={{
            paddingLeft: 5,
            paddingRight: 15,
            paddingTop: 10
        }}>

            {archiveOverviewData.sort(compareCompetitionDate).map((entry, index) => (
                <div
                    key={index}
                    onClick={() => onSelected(entry.id)}
                    style={{
                    width: '100%',
                    margin: 5,
                    padding: 5,
                    backgroundColor: index % 2 === 0 ? colors.red1 : colors.red2 ,
                    color: "white",
                    borderRadius: 6,
                        cursor: 'pointer'
                }}>
                    <div><div style={{fontWeight: 'bold'}}>{entry.name}</div> {entry.location} ({timestampToHumanReadableString(entry.date)})</div>

                </div>
            ))}
        </div>
    )
}

export default CompetitionOverviewArchive
