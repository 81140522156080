// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, initializeFirestore } from "firebase/firestore";
import {getValIfTestEnv, nvl} from "./services/utils";
//import { getAnalytics } from "firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyALoCnw2QCJWVBIy7-z2vRdesVGhmjx9mQ",
    authDomain: "academic-arcade-358908.firebaseapp.com",
    projectId: "academic-arcade-358908",
    storageBucket: "academic-arcade-358908.appspot.com",
    messagingSenderId: "174181875636",
    appId: "1:174181875636:web:ba2fb9098fade79bf33ce1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app, process.env.REACT_APP_DATABASE_NAME? process.env.REACT_APP_DATABASE_NAME :'(default)')
//const analytics = getAnalytics(app);

//connectFirestoreEmulator(db, '127.0.0.1', 8080)
export {db}
