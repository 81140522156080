import React from "react";
import {SimpleGrid, Title} from "@mantine/core";
import AppPreview from "../components/overview/AppPreview";
import letter from '../images/letter.jpg'
import camera from "../images/camera.png"

function Overview() {

    const apps: App[] = [
        {
            name: "Newsletter Generator ",
            path: "newsletter",
            imagePath: letter
        },
        {
            name: "Bild Editor",
            path: "picture",
            imagePath: camera
        },
        ]

    return(
        <div className="App">
            <Title style={{marginLeft: 10}}>TVL Utilities</Title>
            <div style={{display: 'flex', justifyContent: 'left'}}>
            <SimpleGrid
                cols={4}
                spacing='md'
                breakpoints={[
                    {maxWidth: 700, cols: 1, spacing: 'sm'},
                    {maxWidth: 1100, cols: 2, spacing: 'sm'},
                    {maxWidth: 1500, cols: 3, spacing: 'md'}
                ]}
            >
                {apps.map((app) =>
                    <div style={{margin: 20}}>
                    <AppPreview key={app.path} app={app}/>
                    </div>
                )}
            </SimpleGrid>
        </div>
        </div>
    )
}

export default Overview