//let template = require('es6-template-string');
import templateEngine from 'es6-template-string'
import {articleTemplate, base64_tvl_logo, footerTemplate, lineTemplate, mailTemplate} from "./htmlTemplates";
import {links} from "./adLinks";

function generateHTML(mailText: string, articles: Article[]): string{
    const articleHTML = articles.map((article, index) => getHTMLOfArticle(article, index))
    const articlesLinesHTML = getHTMLofArticleLines(articleHTML)
    const footerHTML = getHTMLOfFooter(links)
    const newsletter = getHTMLOfMail(articlesLinesHTML, footerHTML, "<span>"+ mailText + "</span>")
    return newsletter

}

function getHTMLOfArticle(article: Article, position: number): string{
    const pos = position % 2 === 0 ? 'left' : 'right'
    const template = templateEngine.compile(articleTemplate)

    return template({
        position: pos,
        article_title: article.title,
        article_path: article.link,
        article_picture: article.picturePath,
        article_text:article.detail
    })
}

function getHTMLofArticleLines(articleHTMLs: string[], html: string=""): string{
    if(articleHTMLs.length === 0){
        return html;
    }
    if(articleHTMLs.length === 1){
        const newLine = addArticlesToLine(articleHTMLs)
        return html + newLine
    }

    const first: string =  articleHTMLs.shift() ?? ""
    const second: string  = articleHTMLs.shift() ?? ""
    const newLine = addArticlesToLine([first, second])
    return getHTMLofArticleLines(articleHTMLs, html + newLine)
}

function addArticlesToLine(articles: string[]){
    const template = templateEngine.compile(lineTemplate)
    return template({articles: articles.join()})
}

function getHTMLOfFooter(adLinks: string[]){
    const template = templateEngine.compile(footerTemplate)
    return template({
        ad_0: adLinks[0],
        ad_1: adLinks[1],
        ad_2: adLinks[2],
        ad_3: adLinks[3]
    })
}

function getHTMLOfMail(articlesAsLinesHTML: string,  footerHTML: string, text: string,): string{
    const template = templateEngine.compile(mailTemplate)
    return template({
        footer: footerHTML,
        articles: articlesAsLinesHTML,
        text: text,
        tvl_logo: base64_tvl_logo
    })
}

export {
    generateHTML
}