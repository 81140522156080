import React, {useEffect, useState} from "react";
import {Textarea} from "@mantine/core";
import {exportCompetitionAsHTML} from "../../services/HTMLExportService";
import {getCompetition} from "../../services/FirestoreService";

interface Props{
    competitionOverview: CompetitionOverview|undefined
}

function ResultExport({competitionOverview}:Props){

    const [html, setHTML] = useState<string>('<h1>Loading...</h1>')

    useEffect( () => {
        if(!competitionOverview){return;}
        getCompetition(competitionOverview.id)
            .then(competition => {
                setHTML(exportCompetitionAsHTML(competition))
            }
    )}, [competitionOverview])


    return (
        <>
            <Textarea
                autosize
                value={html}
                onChange={(event) => setHTML(event.currentTarget.value)} />
        </>
    )
}

export default ResultExport
