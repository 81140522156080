import React, {useEffect, useState} from "react";

import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../firebase'

interface Props{
    initialDocumentPath: string,
}


function useFirestore({initialDocumentPath}: Props): [{data: any, isLoading: boolean, isError: boolean}, React.Dispatch<React.SetStateAction<string>>]{
    const [data, setData] = useState<any>()
    const [docPath, setDocPath] = useState<string>(initialDocumentPath)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            if(!docPath || docPath === ''){return}
            setIsError(false)
            setIsLoading(true)
            console.log("is loading...")

            const docRef = doc(db, docPath)
            const docSnap = await getDoc(docRef)

            if(docSnap.exists()){
                setData(docSnap.data())
            }else{
                setIsError(true)
            }
            setIsLoading(false)
            console.log("done")
        }

        fetchData().then()

        return () => {}
    }, [docPath])

    return [{data, isLoading, isError}, setDocPath]
}

export default useFirestore