import React, {useEffect, useState} from "react";
import {SimpleGrid} from "@mantine/core";
import {IconRefresh} from '@tabler/icons-react'
import Article from "./Article";

interface Props{
    content: Article[],
    onUpdate: () => void,
    onSelectionChanged: (id: number, isSelected: boolean) => void,
}

function ArticleGrid({content, onUpdate, onSelectionChanged}: Props) {

    const [articles, setArticles] = useState(content)
    useEffect(() => setArticles(content), [content])

    return (
        <>
            <div style={{marginTop: 10, marginRight: 40, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
                    <IconRefresh
                        onClick={onUpdate}
                        size={40}
                        style={{marginLeft: 25, cursor: 'pointer'}}
                    >Update</IconRefresh>
            </div>
            <div
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'center',}}
            >
                <SimpleGrid
                    cols={4}
                    spacing='md'
                    breakpoints={[
                        {maxWidth: 1100, cols: 1, spacing: 'sm'},
                        {maxWidth: 1700, cols: 2, spacing: 'sm'},
                        {maxWidth: 2200, cols: 3, spacing: 'md'}
                    ]}
                >
                    {articles.map((article) =>
                        <Article
                            key={article.id}
                            article={article}
                            onSelectionChanged={onSelectionChanged}
                        />
                    )}
                </SimpleGrid>
            </div>
        </>
    )

}

export default ArticleGrid

//                <Title order={2}>Auswahl Newsberichte</Title>
