import React from "react";
import App from "../App";
import {colors} from "../colors";
import {getValIfTestEnv, isTestEnvironment} from "../services/utils";

interface Props {
    children: JSX.Element
}

export function EnvFrameTop({children}: Props) {
    return (
        <>
            {isTestEnvironment() ? (<>
                <div style={{
                    backgroundColor: colors.warningGreen,
                    height: 5,
                    width: '100%',
                    position: 'fixed',
                    top: 0,
                    zIndex: 10000,
                }}></div>
                <div style={{display: 'flex', flexDirection: "row", justifyContent: 'center', width: '100%',}}>
                    <div style={{
                        backgroundColor: colors.warningGreen,
                        fontWeight: "bold",
                        color: 'black',
                        padding: 6,
                        borderRadius: 15,
                        height: 35,
                        position: "fixed",
                        top: -11,
                        zIndex: 10000,
                    }}>
                        <div style={{
                            paddingTop: 9,
                            fontSize: 12
                        }}>{getValIfTestEnv("TEST ENVIRONMENT", '')}</div>
                    </div>
                    {children}
                </div>
            </>) : <>{children}</>}
        </>
    )
}

export function EnvFrameBottom({children}: Props) {
    return (
        <>
            {getValIfTestEnv(<>
                <div style={{display: "flex", flexDirection: 'row'}}>
                    <div style={{
                        width: 5,
                        backgroundColor: colors.warningGreen,
                        height: '100vh',
                        zIndex: 10001,
                        position: 'fixed'
                    }}></div>
                    <div style={{width: '100%'}}>{children}</div>
                    <div style={{
                        width: 5,
                        backgroundColor: colors.warningGreen,
                        height: '100vh',
                        zIndex: 10001,
                        position: 'fixed',
                        right: 0
                    }}></div>
                </div>
                <div style={{
                    position: "fixed",
                    bottom: 0,
                    height: 5,
                    backgroundColor: colors.warningGreen,
                    width: '100%'
                }}></div>
            </>, <>{children}</>)
            }
        </>
    )

}

export const EnvFrameStyle = {top: getValIfTestEnv(5, 0)}


