import React, {useState} from "react";
import mergeImages from 'merge-images'
import {FileInput, Image, Space, Title, Checkbox, ColorInput, Group, NumberInput, Text} from '@mantine/core';
import download from 'downloadjs'

import {convertFileToImage, getImageDimensions} from "../services/utils";
import {drawRectangle} from "../services/drawRectangle";
import tvlLogo from "../images/tvlLogo.png"

const DEFAuLT_CHECKBOX = ["frame"]
const DEFAULT_COLOR = "#B40024"
const DEFAULT_THICKNESS = 1

function PictureEditorPage() {


    const [imageAsFile, setImageAsFile] = useState<File|null>(null)
    const [outputFile, setOutputFile] = useState<string>("")
    const [selectedSettings, setSelectedSettings] = useState(DEFAuLT_CHECKBOX)
    const [selectedColor, setSelectedColor] = useState<string>(DEFAULT_COLOR)
    const [selectedFrameThickness, setFrameThickness] = useState<number|undefined>(DEFAULT_THICKNESS)

    function onRestSettings(){
        setSelectedSettings(DEFAuLT_CHECKBOX)
        setSelectedColor(DEFAULT_COLOR)
        setFrameThickness(DEFAULT_THICKNESS)
    }

    async function onImageImport(file: File|null){
        if(file === null){
            return
        }
        setImageAsFile(file)
        const image = await convertFileToImage(file)
        processImage(image)

    }

    async function processImage(image: HTMLImageElement){
        const imageSize = await getImageDimensions(image)


        const imagesToMerge = [{src: image.src, x: 0, y: 0}]

        if(selectedSettings.includes("frame")){
            const frame = await addFrame({
                width: imageSize.width,
                height: imageSize.height,
                lineInPercentage: selectedFrameThickness ?? DEFAULT_THICKNESS,
                color: selectedColor})
            imagesToMerge.push(frame)
        }

        if(selectedSettings.includes("logo")){
            const logo = await addLogo(tvlLogo)
            imagesToMerge.push(logo)
        }

        const result = await mergeImages(imagesToMerge)
        setOutputFile(result)
        download(result, imageAsFile?.name ?? "image")

    }

    async function addFrame(frame: FrameDescription): Promise<{src: string, x: number, y: number}>{
        return {src: await drawRectangle(frame), x: 0, y:0}
    }

    async function addLogo(logo: string): Promise<{src: string, x: number, y: number}>{
        return {src: logo, x: 50, y: 50}
    }

    return (
        <div className="App">
            <Title style={{marginLeft: 10}}>Picture Editor</Title>
            <div className="Content">
                <Space h={10}/>

                <Group>
                    <Title order={4}>Einstellungen</Title>
                    <Text color="blue" style={{cursor: "pointer"}} onClick={() => onRestSettings()}>zurücksetzen</Text>
                </Group>
                <Checkbox.Group
                    value={selectedSettings}
                    onChange={setSelectedSettings}
                >
                    <Checkbox  value="frame" label="Rahmen"/>
                    <Checkbox  value="logo" label="Logo"/>
                </Checkbox.Group>
                <Space h={15}/>
                <Group>
                    <ColorInput label="Rahmenfarbe" disabled={!selectedSettings.includes("frame")} value={selectedColor} onChange={setSelectedColor} />
                    <NumberInput label="Rahmendicke in %" disabled={!selectedSettings.includes("frame")} value={selectedFrameThickness} onChange={val => setFrameThickness(val)}/>
                </Group>

                <Space h={15}/>
                <Title order={4} >Bild Auswahl</Title>


                <FileInput
                    // placeholder="Gallerie öffnen"
                    value={imageAsFile}
                    onChange={onImageImport}
                    accept={"image/jpg,image/jpeg,image/png,image/JPG"}
                    style={{width: '80%'}}
                />

                <Space h={15}/>
                <Title order={4} >Vorschau</Title>

                <Image width="90%" src={outputFile}/>

                <Space h={12} />

            </div>
        </div>
    );
}

export default PictureEditorPage