import React from 'react'
import {getAthletesBestResults} from "./FirestoreService";
const initialState = {athleteInformation: {}}

export const AthleteBestResultContext = React.createContext(initialState)

async function AthleteBestResultContextProvider(props){

    return (
        <AthleteBestResultContext.Provider>
            {props.children}
        </AthleteBestResultContext.Provider>
    )
}
