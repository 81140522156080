import React, {useEffect, useRef, useState} from "react";
import {IconShare} from "@tabler/icons-react"
import {Carousel, Embla} from '@mantine/carousel';
import useFirestore from "../../services/hooks/useFirestore";
import {Modal} from "@mantine/core";
import ResultExport from "./ResultExport";
import useScrollDirection from "../../services/hooks/useScrollDirection";
import {colors} from "../../colors";
import {compareCompetitionDate} from "../../services/utils";
import {ARCHIVE_ID} from "../../constants";

interface BoxProps {
    competition: CompetitionOverview,
    showShareIcon?: boolean
}


function Box({competition, showShareIcon = true}: BoxProps): JSX.Element {

    const [opened, setOpened] = useState<boolean>(false)

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title="Exportiere Resultate"
                size={'90%'}
            >
                <ResultExport competitionOverview={competition}/>
            </Modal>

            <div
                style={{
                    borderRadius: 6,
                    backgroundColor: colors.red1,
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    height: 150,
                    paddingBottom: 10

                }}>
                <div
                    style={{
                        display: 'flex',
                        paddingRight: 8,
                        paddingTop: 8,
                        justifyContent: 'flex-end',
                        flex: 1,
                    }}

                >

                    <div className={'share'}>
                        {showShareIcon && <IconShare onClick={() => setOpened(true)}/>}
                    </div>

                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '100%',
                    flex: 10
                }}>
                    <div style={{fontWeight: 800}}>{competition.name}</div>
                    <div>{competition.location}</div>
                </div>
            </div>
        </>
    )
}


interface Props {
    onSlide: (id: string, ref: React.MutableRefObject<Competition[] | undefined>) => void,
    onShowArchive: () => void,
    competitionRef: React.MutableRefObject<Competition[] | undefined>,
    selectedCompetitionId: string | undefined,
    callbackOnlyInArchive: (id: string) => void
}

function CompetitionSelection({
                                  onSlide,
                                  onShowArchive,
                                  competitionRef,
                                  selectedCompetitionId,
                                  callbackOnlyInArchive
                              }: Props) {

    const [{data}] = useFirestore({initialDocumentPath: 'overview/competitions'})
    const scrollDirection = useScrollDirection()
    const stateRef = useRef()
    const [embla, setEmbla] = useState<Embla | null>(null)

    stateRef.current = data?.competitions

    useEffect(() => {
        if (!data || !data.competitions) {
            return;
        }
        //add Slide for Archive to carousel
        data.competitions.push({id: ARCHIVE_ID, name: "vergangene Wettkämpfe", location: "", date: 0})
        //initialize first competition or show archive
        if (data.competitions.length === 1 && data.competitions[0].id === ARCHIVE_ID) {
            onShowArchive()
        } else {
            onSlide(data?.competitions[0].id, competitionRef)
        }
    }, [data])

    /**
     * External change event.
     * Scrolls to the corresponding slide if the Competition that belongs to the id is in the carousel.
     * Otherwise, it calls a callback to notify the parent
     */
    useEffect(() => {
        if (selectedCompetitionId) {
            if (isOnlyInArchive(selectedCompetitionId)) {
                callbackOnlyInArchive(selectedCompetitionId)
                return;
            }
            onCompetitionSlideClicked(selectedCompetitionId)

        }
    }, [selectedCompetitionId]);

    /** Swipe event*/
    const onCompetitionSlideSwiped = (index: number, ref: React.MutableRefObject<Competition[] | undefined>) => {
        if (stateRef.current) {
            // @ts-ignore
            const selectedCompetitionId = stateRef.current[index].id
            if (selectedCompetitionId === ARCHIVE_ID) {
                onShowArchive()
                return
            }
            onSlide(selectedCompetitionId, ref)
        }
    }

    /** Click event*/
    const onCompetitionSlideClicked = (id: string) => {
        if (id === ARCHIVE_ID) {
            onShowArchive()
        }
        embla?.scrollTo(getIndexOfCompetitionById(id))
    }

    /**
     * Not all competitions listed in the archive are also in the carousel.
     * This method returns True if the Id belongs to a competition that is NOT in the carousel
     * @param id string
     * @return true if the Id belongs to a competition that is only in the archive and NOT in the carousel
     */
    const isOnlyInArchive = (id: string): boolean => {
        const index = getIndexOfCompetitionById(id)
        return index < 0
    }

    const getIndexOfCompetitionById = (id: string): number => {
        if (!data) {
            return -1
        }
        return data.competitions.map((competition: Competition) => competition.id).indexOf(id)
    }


    return (
        <div className={`header ${scrollDirection === 'down' ? "hide" : "show"}`}
             style={{paddingTop: 10, paddingBottom: 30}}>
            <Carousel
                withIndicators
                height={'100%'}
                slideSize="20%"
                slideGap="xs"
                loop
                getEmblaApi={setEmbla}
                onSlideChange={(index) => onCompetitionSlideSwiped(index, competitionRef)}
                withControls={false}
                breakpoints={[
                    {maxWidth: 1300, slideSize: '20%'},
                    {maxWidth: 1000, slideSize: '33.333%'},
                    {maxWidth: 700, slideSize: '50%'}
                ]}
            >
                {data?.competitions
                    .sort(compareCompetitionDate)
                    .map((competition: CompetitionOverview) => (
                        <Carousel.Slide key={competition.id} onClick={() => onCompetitionSlideClicked(competition.id)}>
                            <Box
                                competition={competition}
                                showShareIcon={competition.id !== ARCHIVE_ID}
                            />
                        </Carousel.Slide>
                    ))}
            </Carousel>
            <div style={{height: 10, backgroundColor: "white"}}/>
        </div>
    )

}

export default CompetitionSelection;
