import React, {useState} from "react";
import { RichTextEditor } from '@mantine/rte';
import {Button , Group} from '@mantine/core';


const DEFAULT_TEXT = "<h2>Liebe Länggässlerinnen und Länggässler</h2><br>Text...<br><br>Mit sportlichen Grüssen<br>TVL Vorstand"


interface Props{
    title: string,
    setIsSaved: (isSaved: boolean) => void,
    onSave: (text: string) => void,
}

function TextInputField({setIsSaved, onSave}: Props) {

    const [text, setText] = useState<string>(DEFAULT_TEXT)

    function onTextChange(text: string) {
        setText(text)
        setIsSaved(false)
    }

    function save(text: string){
        setIsSaved(true)
        onSave(text)
    }

    return (
        <>

            <RichTextEditor
                className={"Editor"}
                value={text}
                onChange={onTextChange}
                controls={[
                    ['h1','h2', 'h3', 'h4', 'h5', 'h6'],
                    ['bold', 'italic', 'underline', 'link'],
                    ['clean']
                ]}
            />
            <Group style={{marginTop: 10}}>
                <Button className={"Button"}  onClick={() => save(text)}>Text speichern</Button>
            </Group>

        </>

    )
}

export default TextInputField

//<Title order={2} style={{marginBottom: 10}}>{title}</Title>