import * as React from "react"
import SimpleBoxTemplate from "../components/SimpleBoxTemplate";
import {SimpleGrid} from "@mantine/core";
import DisciplineBox from "../components/results/DisciplineBox";
import {colors} from "../colors";
import {IconMedal2} from "@tabler/icons-react";


function Nick() {
    return (
        <div className={'App'} style={{margin: 10}}>
            <SimpleGrid
                cols={3}
                spacing='md'
                breakpoints={[
                    {maxWidth: 430, cols: 1, spacing: 'sm'},
                    {maxWidth: 740, cols: 2, spacing: 'sm'},
                ]}
            >
                <AchievementBox title="Nick vs Sylvain Chuard" result="10:1"/>
                <AchievementBox title="CH Meister 400m U23" result="2x"/>
                <AchievementBox title="CH Meister 400m Aktive" result="0x"/>
                <AchievementBox title="PB 200m" result="21.02"/>
                <AchievementBox title="PB 400m" result="47.35"/>
            </SimpleGrid>
        </div>

    )


}

interface AchievementBoxProps {
    title: string
    result: string|JSX.Element
}

const AchievementBox = ({title, result}:AchievementBoxProps) => (
    <DisciplineBox
        title={title}
        disciplineId={""}
        competitionId={""}
        deactivateHyperlink
    >
        <AchievementBoxContent result={result}/>
        <div></div>
    </DisciplineBox>
)

interface AchievementBoxContentProps {
    result: string|JSX.Element
}
const AchievementBoxContent = ({result}: AchievementBoxContentProps) => (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        fontSize: 100,
                        lineHeight: 1,
                        height: '100%',
                        fontWeight: "bold",
                        color: colors.tvlRed
                    }}>{result}</div>
    </div>
)

export default Nick
