import React from "react";
import { useNavigate } from "react-router-dom";


import {Image, Card, Group, Title} from "@mantine/core";

interface Props{
    app: App
}

function AppPreview({app}: Props) {

    const navigate = useNavigate()

    function onClick( ) {
        navigate(`/${app.path}`)
    }

    return (
            <Card
                style={{cursor: 'pointer'}}
                shadow='sm'
                p="lg"
                radius="md"
                withBorder
                onClick={onClick}
            >
                <Card.Section>
                    <Image
                        src={app.imagePath}
                        height={160}
                        alt="Preview of App"
                        draggable={true}
                    />
                </Card.Section>
                <Group position="center" mt="md" mb="xs">
                        <Title order={2} style={{color: 'rgba(180,0,36,1)'}}>{app.name}</Title>
                </Group>
            </Card>
    )

}
export  default AppPreview

/*
    <SimpleBoxTemplate
            title={app.name}
            onClick={onClick}
            hovering
        >
        </SimpleBoxTemplate>
 */