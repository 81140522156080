

export function mapDisciplineGroupsToName(group: string){
    const translation = MAPPING[group]
    return translation? translation : group //fallback
}

export function exportCompetitionAsHTML(competition: Competition): string{

    const disciplines = joinDisciplineGroups(JSON.parse(JSON.stringify(competition.disciplines)))
    const [men, women ] = splitDisciplinesByGender(disciplines)

    return `
        <div>
            ${getTable(men, "Resultate TVL-Männer ")}
            ${getTable(women, "Resultate TVL-Frauen")}
        </div>
    `
}

function getTable(disciplines: DisciplineCollection, title: string): string{

    const lines = []

    for(const discipline in disciplines){
        lines.push(...disciplines[discipline].sort(compareResults).map((result, index) => getTableLine(result, !index)))
    }

    const table = `
            <br>
            <p><strong>${title}</strong></p>
            <table>
                <colgroup>
                    <col width="120">
                    <col width="250">
                    <col width="80">
                    <col width="200">
                </colgroup>
                <tbody>
                    ${lines.join('\n')}
                </tbody>
            </table>`

    return table
}

function getTableLine(result: Result, isFirstOfDiscipline: boolean){

    const discipline = isFirstOfDiscipline? getCell(mapDisciplineGroupsToName(result.disciplineGroup)) : '<td></td>'
    const name = getCell(result.athleteName)
    const resultValue = getCell(result.result)
    const vintage = getCell(result.vintage)
    return `<tr>
                ${discipline}
                ${name}
                ${vintage}
                ${resultValue}
            </tr>`
}

function getCell(value: string, rowSpan?: string, className?: string): string{
    const classAttr = className? `class="${className}"` : ''
    return `<td ${classAttr}>${value? value : ''}</td>`
}

const  getResultWithInfo = (result: Result): string  => `${result.result}${ result.info? " (" +result.info + ")": ''}`

function joinDisciplineGroups(disciplines: DisciplineCollection): DisciplineCollection{
    const allResults = Object.values(disciplines).flat(1)
    const groupedByGroup = groupBy(allResults, 'disciplineGroup')


    for(const group in groupedByGroup){
        const groupedByAthlete = groupBy(groupedByGroup[group], 'athleteName')
        const joinedResults = Object.values(groupedByAthlete).map(list => {
            const result = list[0]
            result.resultValues = list.map(result => parseResultStringToNumber(result.result))
            result.result =  list.map(result => getResultWithInfo(result)).join(', ')
            return result
        }).flat(1)
        groupedByGroup[group] = joinedResults
    }

    return groupedByGroup
}

function splitDisciplinesByGender(disciplines: DisciplineCollection): DisciplineCollection[]{
    const men: DisciplineCollection = {}
    const women: DisciplineCollection = {}

    const addResult = (disciplineCollection: DisciplineCollection, disciplineName: string, result: Result) => {
        if(!disciplineCollection[disciplineName]){
            disciplineCollection[disciplineName] = []
        }
        disciplineCollection[disciplineName].push(result)
    }

    Object.keys(disciplines).forEach(disciplineName => {
        disciplines[disciplineName].forEach(result => {
            if(isManCategory(result.disciplineFullName)){
                addResult(men, disciplineName, result)
            }else{
                addResult(women, disciplineName, result)
            }
        })
    })

    return [men, women]

/*
    Object.keys(disciplines).forEach(disciplineName => {
        if(isManCategory(disciplineName)){
            men[disciplineName] = disciplines[disciplineName]
        }else{
            women[disciplineName] = disciplines[disciplineName]
        }
    })

 */

}

function isManCategory(disciplineName: string){
    const manCategoryIndicators = ["Männer", "Mann","Hommes", "Uomini", "Uomo", "MAN", "U10M", "U12M", "U14M", "U16M", "U18M", "U20M", "U23M"]
    return manCategoryIndicators.some(indicator => disciplineName.includes(indicator))
}

const MAPPING: {[name: string]: string}  = {
    '60M': '60m',
    '60H': '60m Hürden',
    '80H': '80m Hürden',
    '100': '100m',
    '10H': '100m Hürden',
    '11H': '110m Hürden',
    '150': '150m',
    '200': '200m',
    '300': '300m',
    '400': '400m',
    '40H': '400m Hürden',
    '600': '600m',
    '800': '800m',
    '1K0': '1000m',
    '1K5': '1500m',
    '3K0': '3000m',
    '5K0': '5000m',
    'WEI': 'Weitsprung',
    'WEZ': 'Weitsprung Zone',
    'DRE': 'Dreisprung',
    'HOC': 'Hochsprung',
    'STA': 'Stabhochsprung',
    'KUG': 'Kugel',
    'SPE': 'Speer',
    'HAM': 'Hammer',
    'DIS': 'Diskus'
}

// (copied from tvl-results cloud-functions project)
function parseResultStringToNumber(result: string) {
    const map = {
        original: result,
        main: 0,
        sub: 0
    }

    if(result === ''){
        return 0
    }

    if (map.original.includes(':')) {
        const split: string[] = map.original.split(":")
        map.main += Number(split[0]) * 60 /*transform minutes to second*/
        map.original = split[1]
    }

    if (result.includes(',')) {
        const split = map.original.split(",")
        map.main += Number(split[0])
        map.sub += Number(split[1])
    }

    return map.main + (map.sub / 100)
}


function compareResults(a: Result, b: Result){
    let comparisonResult = 0
    let comparisonFactor = 1
    let minMaxFunction = Math.max
    let invalidResultConstant = 0

    // checks if the DisciplineGroup contains a number (e.g. 11H or 1K0)
    // if so, it's probably a run and a smaller number is a better result
    // -> change the order with multiplying it with -1
    // -> use the highest number in result-list
    // -> set a small number as alternative to ensure the value is at the end of the list (e.g. 'aufg.', 'DNS', 'n.a' etc)
    if(/\d/.test(a.disciplineGroup)) {
        comparisonFactor = -1
        minMaxFunction = Math.min
        invalidResultConstant = 1_000_000
    }

    // select the best result
    let resultA: number = a.resultValues?.length ? minMaxFunction(...a.resultValues) : 0
    let resultB: number = b.resultValues?.length ? minMaxFunction(...b.resultValues) : 0

    //replace zero values (for example if the result would be "DNS" or "aufg." with a constant, depending on if it's a run or not)
    resultA = resultA === 0? invalidResultConstant : resultA
    resultB = resultB === 0? invalidResultConstant : resultB

    if(resultB > resultA){comparisonResult = 1}
    else if(resultA > resultB) {comparisonResult = -1}

    // change order with multiplying with -1 if it's a run
    return comparisonResult * comparisonFactor
}

function groupBy<Type>(list: Type[], key: string): {[key: string]: Type[]} {
    return list.reduce(function(rv, x) {
        // @ts-ignore
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}
