export const colors =  {
    primary:'rgba(36,159,231,0.5)' ,//'rgba(245,138,163,0.47)',
    secondary:'rgb(15,152,232)', //'rgb(245,138,163)',
    gray: 'gray',
    tvlRed: 'rgba(180,0,36,1)',
    lightRed: 'rgba(180,0,36,1)',//'rgb(245,138,163)',
    darkRed: 'rgba(245,138,163,0.47)',
    red1: 'rgba(180,0,36,0.85)',
    red2: 'rgba(180,0,36,0.65)',
    red3: 'rgba(180,0,36,0.4)',
    warningGreen: 'rgb(24,239,1)'
}
