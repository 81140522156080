import axios from "axios";
import {getValIfTestEnv} from "../services/utils";

//const baseURL: string =  "https://getnewsarticles-q77x4v7yva-uc.a.run.app/" //http://127.0.0.1:5001/tvl-newsletter/us-central1/getNewsArticles"
const baseUrl: string = "https://us-central1-academic-arcade-358908.cloudfunctions.net"
async function getArticles(): Promise<Article[]>{
    const url = `${baseUrl}/getNewsArticles${getValIfTestEnv('Test', '')}`

    const {data} = await axios.get<Article[]>(url)
    return data
}


export {
    getArticles
}
