import React, {useEffect, useState} from 'react';
import {MantineProvider} from '@mantine/core';
import {BrowserRouter as Router} from "react-router-dom";

import './App.css';
import AppRouter from "./pages/AppRouter";
import {AthleteBestResultContext} from "./services/ContextProvider";
import {getAthletesBestResults} from "./services/FirestoreService";

function App() {

    const [athletesBestResults, setAthletesBestResult] = useState<AthletesBestResults>({athleteInformation: {}})
    useEffect(() => {
        getAthletesBestResults().then(athletesBestResults => setAthletesBestResult(athletesBestResults))
    }, [])

    return (
        <MantineProvider
            withCSSVariables
            withGlobalStyles
        >
            <AthleteBestResultContext.Provider value={athletesBestResults}>
                <Router>
                    <AppRouter/>
                </Router>
            </AthleteBestResultContext.Provider>
        </MantineProvider>
    )

}

export default App;


