import React, {ReactElement, useEffect, useState} from "react";
import {IconCheck, IconX} from "@tabler/icons-react";
import {Badge, Text} from '@mantine/core';

interface Props{
    isSaved: boolean,
}

interface BadgeAttr{
    color: string,
    text: string,
    icon: ReactElement
}

const savedAttr: BadgeAttr = {
    color: 'green',
    text: 'saved',
    icon: <IconCheck size={20} style={{marginRight: 10, marginTop: 5}}/>
}
const notSavedAttr: BadgeAttr = {
    color: 'gray',
    text: 'not saved',
    icon: <IconX size={20} style={{marginRight: 10, marginTop: 5}}/>
}

function SavedIndicator({isSaved}: Props) {

    const [state, setState] = useState<BadgeAttr>(isSaved? savedAttr: notSavedAttr)
    useEffect(() => {setState(isSaved? savedAttr : notSavedAttr)}, [isSaved])

    return (

            <Badge  size='md' color={state.color} style={{marginLeft: 10}} >
                <div style={{display: 'inline-flex', alignItems: "center"}}>
                    {state.icon}
                    <Text style={{marginTop: 7}}>{state.text}</Text>
                </div>

            </Badge>
    )
}


export default SavedIndicator
