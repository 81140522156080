import React from "react";
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import {Link} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css'
import logo from '../../images/tvlLogo.png'
import {colors} from "../../colors";
import {EnvFrameStyle, EnvFrameTop} from "../EnvFrame";

interface MenuEntries {
    links: { path: string, label: string }[]
}


function AppMenu({links}: MenuEntries) {
    return (
        <>
        <EnvFrameTop>
            {/*
            //@ts-ignore */}
            <Navbar
                style={{
                    ...{background: colors.tvlRed},
                    ...EnvFrameStyle
                }}
                variant="dark"
                fixed="top"
                expand="sm"
                collapseOnSelect
            >
                {/*
                //@ts-ignore */}
                <Navbar.Brand>
                    <img
                        src={logo}
                        style={{width: 50, marginLeft: 20}}
                        alt={"TVL Logo"}
                    />
                </Navbar.Brand>
                {/*
                  //@ts-ignore */}
                {links.length ? <Navbar.Toggle/> : <></>}
                <Navbar.Collapse>
                    {/*
                   //@ts-ignore */}
                    <Nav style={{marginLeft: 15}}>
                        {links.map((entry, index) => (
                            //@ts-ignore
                            <Nav.Link eventKey={index + 1} as={Link} to={entry.path}>{entry.label}</Nav.Link>)
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </EnvFrameTop>
        </>
    )
}

export default AppMenu

