import React from "react";
import {colors} from "../../colors";

const BASE_URL = 'https://slv.laportal.net/Competitions/CurrentList'

const getURL = (disciplineId: string, competitionId?: string ) => competitionId? `${BASE_URL}/${disciplineId}/${competitionId}` : ''

interface Props{
    title: string,
    competitionId?: string
    disciplineId: string
    deactivateHyperlink?: boolean
    children: JSX.Element[]
}

function DisciplineBox({title, competitionId, disciplineId, deactivateHyperlink=false, children}:Props){

    return(
        <div
            style={{
                // Body
                backgroundColor: colors.red3,
                borderRadius: 6,
                marginLeft: 6,
                marginRight: 6,
            }}
        >
            <a style={{
                // Header
                display: 'flex',
                borderRadius: 6,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
                padding: 4,
                paddingLeft: 8,
                color: 'white',
                fontWeight: 700,
                backgroundColor: colors.red2,
                pointerEvents: deactivateHyperlink? 'none' : 'all'
                }}
               className='disciplineLink'
               target="_blank"
               href={getURL(disciplineId, competitionId)}
            >
                {title}
            </a>
            <div style={{padding: 10}}>
                {children}
            </div>

        </div>
    )
}

export default DisciplineBox
