import React, {useEffect, useRef, useState} from 'react'
import CompetitionSelection from "../components/results/CompetitionSelection";
import {getAthletesBestResults, getCompetition, getCompetitionOverviewArchive} from "../services/FirestoreService";
import Competition from "../components/results/Competition";
import CompetitionOverviewArchive from "../components/results/CompetitionOverviewArchive";

function ResultsPage(){

    const [fetchedCompetitions, setFetchedCompetitions] = useState<Competition[]>([])
    const [selectedCompetition, setSelectedCompetition] = useState<Competition>()
    const fetchedCompetitionsRef =  useRef<Competition[]>()
    const [athleteBestResults, setAthleteBestResults] = useState<AthletesBestResults>({athleteInformation: {}})

    //competitionOvewviewArchive
    const [competitionArchiveData, setCompetitionArchiveData] = useState<Competition[]>([])
    const [showArchive, setShowArchive] = useState<boolean>(false)
    const [showCompetitionHeader, setShowCompetitionHeader] = useState<boolean>(false)

    const [currentActiveCompetitionSlideId, setCurrentActiveCompetitionSlideId] = useState<string|undefined>(undefined)

    fetchedCompetitionsRef.current = fetchedCompetitions


    useEffect(() => {
        getAthletesBestResults().then(athleteBestResults => setAthleteBestResults(athleteBestResults))
        getCompetitionOverviewArchive().then(competitionArchive => setCompetitionArchiveData(competitionArchive))
    }, [])

    const onSlide = (id: string, ref: React.MutableRefObject<Competition[]|undefined>) => {
        setShowCompetitionHeader(false)
        setCurrentActiveCompetitionSlideId(undefined)
        displayCompetitionData(id, ref)
    }

    const onArchiveSelected = (id: string) => {
        //The Carousel in the CompetitionSelection-Component scrolls to the corresponding Slide (it uses a use-effect-mechanism)
        // if the Competition is not in the carousel (only in the archive), it calls a callback method
        setCurrentActiveCompetitionSlideId(id)
    }

    const callbackIsOnlyInArchive = (id: string) => {
        displayCompetitionData(id, fetchedCompetitionsRef)
    }

    const displayCompetitionData = (id: string, ref: React.MutableRefObject<Competition[]|undefined>) => {
        if(!ref || !ref.current){return;}
        setShowArchive(false)
        const selected = ref.current.find(competition => competition.id === id)
        if (selected) {
            setSelectedCompetition(selected)
        } else {
            getCompetition(id).then(competition => {
                setSelectedCompetition(competition)
                setFetchedCompetitions(fetched => {
                    const newList = [...fetched]
                    newList.push(competition)
                    return newList
                })
            })
        }
    }

    const onShowArchive = () => {
        setShowCompetitionHeader(true)
        setShowArchive(true)
        //reset so that repeated clicking is perceived as a change
        setCurrentActiveCompetitionSlideId(undefined)
    }

    return (
        <div className={'App'} style={{marginTop: 10}}>
            <CompetitionSelection
                onSlide={onSlide}
                onShowArchive={onShowArchive}
                competitionRef={fetchedCompetitionsRef}
                selectedCompetitionId={currentActiveCompetitionSlideId}
                callbackOnlyInArchive={callbackIsOnlyInArchive}
            />
            {!showArchive && <Competition competition={selectedCompetition} showHeader={showCompetitionHeader} onBack={onShowArchive}/>}
            {showArchive && <CompetitionOverviewArchive
                archiveOverviewData={competitionArchiveData}
                onSelected={onArchiveSelected}
            />}
        </div>
    )
}

export default ResultsPage

