import React, {useEffect, useState} from "react";
import {Modal} from "@mantine/core";

interface Props{
    opened: boolean,
    onClose: (isOpen: boolean) => void,
    html: string
}

function calcModalSize(){
    const {innerWidth: width} = window;
    const defaultModalWidth = 800
    if(width <= defaultModalWidth){
        return '95%'
    }
    return defaultModalWidth
}


function PreviewModal({opened, onClose, html}: Props){

    const [showModal, setShowModal] = useState<boolean>(opened)
    useEffect(() => setShowModal(opened), [opened])

    function closeModal(isOpen: boolean){
        onClose(isOpen)
        setShowModal(isOpen)
    }

    const [modalSize, setModalSize] = useState<number|string>(() => calcModalSize())

    useEffect(() => {

        function handleResize(){
            setModalSize(calcModalSize)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)

    }, [])




    return(
        <Modal opened={showModal} size={modalSize} onClose={() => closeModal(false)}>
            <div dangerouslySetInnerHTML={{__html: html}}/>
        </Modal>
    )

}

export default PreviewModal