
async function convertFileToImage(file: File): Promise<HTMLImageElement>{
    const image = new Image()
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
        reader.onload = event => {
            if(event.target === null || event.target.result === null || event.target.result instanceof ArrayBuffer){
                reject("target or result is null")
                return
            }
            image.src = event.target.result
            resolve(image)
        }
        reader.readAsDataURL(file)
    })
}

async function getImageDimensions(image: HTMLImageElement): Promise<ImageSize>{
    return new Promise(resolve => {
        image.onload = () => {
            resolve({height: image.height, width: image.width})
        }
    })
}

function getAgeCategory(vintage: number | string, prefix?: string ): string{
    const currYear = new Date().getFullYear()
    const age = currYear - Number(vintage)
    let category = ""
    if(age <= 9){category = "U10"}
    else if(age <= 11){category =  "U12"}
    else if(age <= 13){category =  "U14"}
    else if(age <= 15){category =  "U16"}
    else if(age <= 17){category =  "U18"}
    else if(age <= 19){category =  "U20"}
    else if(age <= 22){category =  "U23"}

    if(prefix && category !== ''){
        category = prefix + category
    }
    return category
}

function compareCompetitionDate(a: Competition, b: Competition): number {
    return b.date - a.date
}

function timestampToHumanReadableString(timestamp: number): string {
    const date = new Date(timestamp )
    const month = (date: Date): string => (date.getMonth() +1)< 10? `0${date.getMonth()+1}` : `${date.getMonth()+1}`
    return `${date.getDate()}.${month(date)}.${date.getFullYear()}`
}

function isTestEnvironment(): boolean{
    return process.env.REACT_APP_ENV === 'test'
}

function getValIfTestEnv<T>(getVal: T, otherwise: T): T{
    return isTestEnvironment()? getVal : otherwise
}

function nvl<T>(value: T, otherwise: T ): T{
    return value ? value : otherwise
}

export {
    getImageDimensions,
    convertFileToImage,
    getAgeCategory,
    compareCompetitionDate,
    timestampToHumanReadableString,
    isTestEnvironment,
    getValIfTestEnv,
    nvl
}
