import {
    Path,
    Group,
    exportImage,
} from "@progress/kendo-drawing";

async function drawRectangle(frame: FrameDescription): Promise<string>{

    const pathThickness = getLineThickness(frame)

    const path = new Path({
        stroke: {
            color: frame.color,
            width: pathThickness
        }
    })

    path.moveTo(0,0).lineTo(0, frame.height-pathThickness).lineTo(frame.width-pathThickness, frame.height-pathThickness).lineTo(frame.width-pathThickness, 0).lineTo(0,0).close()
    const group = new Group()
    group.append(path)

    return await exportImage(group)
}


function getLineThickness(image: FrameDescription){
    const maxValue = Math.min(image.width, image.height)
    if(image.lineInPercentage > 100 || image.lineInPercentage < 0){
        image.lineInPercentage = 0.01
    }
    if(image.lineInPercentage >= 1){
        image.lineInPercentage = image.lineInPercentage / 100
    }
    console.log(image.lineInPercentage)

    return maxValue * image.lineInPercentage
}

export {
 drawRectangle
}