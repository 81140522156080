import React, {useContext, useEffect, useState} from "react";
import {IconChevronLeft, IconShare} from "@tabler/icons-react";
import SimpleBoxTemplate from "../SimpleBoxTemplate";
import {SimpleGrid, Tooltip, Modal, Alert} from "@mantine/core";
import {IconAlertCircle} from '@tabler/icons';
import DisciplineBox from "./DisciplineBox";
import {AthleteBestResultContext} from "../../services/ContextProvider";
import {mapDisciplineGroupsToName} from "../../services/HTMLExportService";
import {getAgeCategory} from "../../services/utils";
import {colors} from "../../colors";
import ResultExport from "./ResultExport";
import {ARCHIVE_ID} from "../../constants";

interface CompetitionProps {
    competition: Competition | undefined,
    showHeader: boolean,
    onBack: () => void

}

interface DisciplineProps {
    group: string,
    competitionId?: string,
    disciplineId: string,
    results: Result[]
}

interface ResultProps {
    result: Result
}

interface CompetitionHeader {
    competition: Competition
    onBack: () => void
}

function Competition({competition, showHeader, onBack}: CompetitionProps) {

    const [disciplines, setDisciplines] = useState<DisciplineCollection>({})

    useEffect(() => {
        if (!competition) {
            return;
        }
        setDisciplines(competition.disciplines)
    }, [competition])

    return (

        <div style={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 10
        }}>
            {showHeader && competition && <CompetitionHeader competition={competition} onBack={onBack}/>}
            {Object.keys(disciplines).length === 0 && competition?.id !== ARCHIVE_ID ?
                <SimpleBoxTemplate>{<div>Keine Daten verfügbar.</div>}</SimpleBoxTemplate> : (
                    <>
                        <SimpleGrid
                            cols={3}
                            spacing='md'
                            breakpoints={[
                                {maxWidth: 430, cols: 1, spacing: 'sm'},
                                {maxWidth: 740, cols: 2, spacing: 'sm'},
                            ]}
                        >
                            {Object
                                .keys(disciplines)
                                .sort()
                                .map(disciplineName => (
                                    <Discipline
                                        key={disciplineName}
                                        results={disciplines[disciplineName]}
                                        group={disciplineName}
                                        disciplineId={disciplines[disciplineName][0].disciplineId}
                                        competitionId={competition?.id}
                                    />
                                ))}
                        </SimpleGrid>
                        <div style={{height: 15, backgroundColor: "white"}}/>
                    </>
                )}
        </div>
    )
}


function Discipline({results, competitionId, disciplineId, group}: DisciplineProps) {

    const getKey = (result: Result) => `${result.disciplineId}${result.athleteName}`
    const compare = (a: Result, b: Result) => {
        if (a.athleteName > b.athleteName) return 1
        if (a.athleteName < b.athleteName) return -1
        return 0
    }

    return (
        <DisciplineBox title={group} disciplineId={disciplineId} competitionId={competitionId}>
            {results.sort(compare).map(result => <Result key={getKey(result)} result={result}/>)}
        </DisciplineBox>
    )
}

function Result({result}: ResultProps) {
    const resultWithInfo = `${result.result}${result.info ? " (" + result.info + ")" : ''}`
    const athleteBestResultsGlobalData: AthletesBestResults = useContext(AthleteBestResultContext)
    const [opened, setOpened] = useState<boolean>(false)

    return (
        <>
            <Tooltip label={result.disciplineFullName}>
                <div onClick={() => setOpened(true)}>{`${result.athleteName}: ${resultWithInfo}`}</div>
            </Tooltip>

            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={<b>Persönliche Bestleistungen
                    von {result.athleteName} {result.vintage ? `(${result.vintage}${getAgeCategory(result.vintage, '/')})` : ""}</b>}
                style={{marginTop: 50}}
            >

                <Alert
                    icon={<IconAlertCircle size={16}/>}
                    title='Hinweis'
                    color='red'
                    style={{marginBottom: 10}}
                >
                    Aktuell werden noch nicht bei Allen die effektiven Bestleistungen angezeigt.
                </Alert>
                {Object.keys(athleteBestResultsGlobalData.athleteInformation)
                    .filter(athleteName => athleteName === result.athleteName)
                    .map((athleteName: string) => athleteBestResultsGlobalData.athleteInformation[athleteName].disciplines)
                    .map((disciplines) => {
                        return <>{Object.keys(disciplines).map(id => (
                            <div>{mapDisciplineGroupsToName(disciplines[id].disciplineGroup)}: <b>{disciplines[id].valueLabel}</b>
                            </div>))}</>
                    })
                }
            </Modal>
        </>

    )
}

function CompetitionHeader({competition, onBack}: CompetitionHeader) {

    const [modalResultExportOpened, setModalResultExportOpened] = useState<boolean>(false)

    return (
        <>
            <Modal
                // only used for Competitions shown in Archive. Other Competitions opens their ExportModal via Carousel
                opened={modalResultExportOpened}
                onClose={() => setModalResultExportOpened(false)}
                title="Exportiere Resultate"
                size={'90%'}
            >
                <ResultExport competitionOverview={competition}/>
            </Modal>
            <div
                style={{
                    backgroundColor: colors.red1,
                    paddingLeft: 5,
                    padding: 5,
                    margin: 5,
                    borderRadius: 6,
                    color: "white",
                    fontWeight: "bold",
                    display: 'flex',
                    justifyContent: "space-between"

                }}

            >
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div
                        onClick={onBack}
                        style={{cursor: 'pointer', paddingRight: 10}}><IconChevronLeft/></div>
                    <div>{competition?.name}</div>
                </div>
                <IconShare
                    style={{cursor: 'pointer', paddingRight: 3}}
                    onClick={() => setModalResultExportOpened(true)}/>
            </div>
        </>
    )
}


export default Competition
